<template>
  <div>
    <Carousel :carouselList="data.banner"/>    <!--    轮播-->

    <!--    内容-->
    <div class="container">

      <div class="title">
        <span>生产制造企业营销管理产品矩阵</span>
        <div>9大功能点满足企业核心营销需求</div>
      </div>


      <div class="jiuGongGeList" ref="product">
        <router-link :to="'/product?id='+v.id" v-for="(v,i) in data.product" :key="i">

          <div class="cursor normal" :style="{

          }">
            <div class="t">{{ v.title }}</div>
            <div class="txt">
              <div style="margin: 0 10px 0 0 " v-for="(vv,vi) in v.list"> {{ vv }}</div>
            </div>


          </div>
        </router-link>
      </div>
      <!--      6.19新增一物一码营销玩法-->
      <div style="margin-top: 80px">
        <img src="../../assets/oneCode.png">
      </div>
<!--      <div class="solution">-->
<!--        <div class="title">-->
<!--          <span>一物一码营销玩法</span>-->
<!--        </div>-->

<!--        <div class="swiper-container">-->
<!--          <div class="swiper-wrapper">-->
<!--            1111啛啛喳喳-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="solution">

        <div class="title">
          解决方案
          <div>多样化的行业方案，一站式解决营销和产品管理难题</div>
        </div>


        <div class="swiper-container">

          <div class="swiper-wrapper">

            <div class="swiper-slide" style="width: 300px" v-for="(v,i) in data.solution" :key="i">

              <div class="box">

                <!-- 大-->
                <div class="big">
                  <div class="t">{{ v.title }}行业解决方案</div>
                  <img :src="v.plan" alt="">
                  <router-link :to="'/solution?id='+v.id">
                    <div class="btn" @click="solutionJump">了解详情</div>
                  </router-link>
                </div>

                <!-- 小-->
                <div class="small">
                  <img :src="v.plan" alt="">
                  <div class="t">{{ v.title }}行业解决方案</div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>


      <div class="caseShow">
        <div class="title"><span>案例展示</span></div>
        <div class="list" ref="case">
          <router-link :to="v.id ? `/case?id=${v.id}` : '/'" v-for="(v,i) in data.case" :key="i">
            <div class="cursor  ">{{ v.title }}</div>
          </router-link>
        </div>
      </div>


      <div class="CustomerLogoWall">
        <div class="title"><span>客户墙</span></div>

        <div class="logoCarousel">

          <el-carousel indicator-position="none">
            <el-carousel-item v-for="item in [ require('../../assets/home/l1.png'),require('../../assets/home/l2.png')]"
                              :key="item">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>


      <!--新闻中心-->
      <div class="newsCenter">
        <div class="title"><span>新闻中心</span></div>


        <div class="newsBox">
          <div class="n">
            <div @click="newsType = 1"
                 :class="['left','cursor',newsType === 1?'selected':'',]"
            >企业动态
            </div>
            <div @click="newsType= 2"
                 :class="['left','cursor',newsType === 2?'selected':'',]"
            >行业资讯
            </div>
          </div>
          <div class="box">
            <div class="cursor" v-for="(v,i) in newsCenterArr" :key="i">
              <router-link :to="`/news?type=${v.type}&id=${v.id}`">
                <div class="ig">
                  <img :src="v.img" alt="">
                </div>
                <div class="text">
                  <div class="t">{{ v.title }}</div>
                  <div class="time">发布时间：{{ v.time }}</div>
                  <div class="txt ">{{ v.text | filterText }}</div>
                </div>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Carousel from "@/components/Carousel";
import Swiper from 'swiper';
import 'swiper/swiper.less';
import {scrolTop} from "../../utils/ decorator";
import {home} from "@/api/home";

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      carouselList: [require("../../assets/banner/1.png"), require("../../assets/banner/2.png"), require("../../assets/banner/3.png")],
      newsType: 1,
      data: {}
    }
  },
  @scrolTop
  async created() {
    const {data, code, message} = await home();

    if (code !== 200) return this.$message.error(message);
    this.data = data;
    localStorage.setItem('data', JSON.stringify(data));
    // this.data = JSON.parse( localStorage.getItem( 'data' ) );

    console.log(this.data.case);

    // this.data.case.splice(9, 0, {title: "和畅食品"}, {title: "湖南胖哥"}, {title: "瑞象化工"}, {title: "雪兰乳业"}, {title: "中盐集团"}, {title: "航佳生物"})   6.19后端配置好取消写死

    let newData = this.data.product.filter(f => f.id !== 6);

    newData.splice(6, 0,
        {
          id: 6,
          title: '营销费用管理',
          list: [
            "活动管理 | 陈列费管理",
            "费用核销 | 费用申请",
            "费用审批 | 费用分析",
          ]
        },
        {
          id: 6,
          title: '订单管理',
          list: [
            "余额、往来账 | 分级管理",
            "促销返利 | 费用核销",

          ]
        },
        {
          id: 6,
          title: 'SFA销售行为管理',
          list: [
            "出勤销售力 | 达标销售力",
            "拜访数据 | 拜访计划",
            "终端检核率 | 终端开拓"
          ]
        }
    )


    this.data.product = newData;

    // console.log( newData );
    // console.log( this.data.product );

  },
  async beforeCreate() {

    // const { data, code, message } = await home();
    //
    // if ( code !== 1000 ) return this.$message.error( message );
    //
    // this.data = data;
    // console.log("主页加载前1111")
    // localStorage.setItem( 'data', JSON.stringify( data ) );
  },
  mounted() {
    const swiperContainer = new Swiper('.swiper-container', {
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      slideToClickedSlide: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: true,
        disableOnInteraction: false,
      },
    });

    setInterval(() => {
      swiperContainer.slideNext();
    }, 2000);

    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll(e) {
      const domOffsetTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      // if (domOffsetTop > 300) {
      //   this.$refs.case.classList.add('animate__fadeInDown')
      // }

    },
    solutionJump() {
    },

  },
  destroyed() {
  },

  computed: {
    newsCenterArr() {
      return this.data.news.filter(r => r.type == this.newsType);
    }
  },


};
</script>


<style lang="less" scoped>

.container {
  width: auto;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .title {
    padding: 60px 0 47px 0;
    text-align: center;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;


    span {
      border-bottom: 2px solid #7789C0;
      padding-bottom: 5px;
    }

    > div {
      margin-top: 15px;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      color: #999999;
    }
  }

  .jiuGongGeList {
    width: 1310px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > a {
      position: relative;

      .normal {
        width: 435px;
        height: 207px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: 400;
        background-size: 100% 100%;
        color: #004175;
        border-top: 1px solid rgba(212, 212, 222, 1);
        border-left: 1px solid rgba(212, 212, 222, 1);
        //padding-left: 40px;
        //padding-top: 35px;


        .icon {

          font-size: 66px;

          background-image: -webkit-linear-gradient(bottom, #1A4EFF, #1777EB);

          -webkit-background-clip: text;

          -webkit-text-fill-color: transparent;

        }

        .t {
          font-size: 25px;
          font-family: '微软雅黑', serif;
          font-weight: 500;
          color: #1777EC;
          margin-bottom: 15px;
        }


        .txt {
          //width: 280px;
          //height: 100%;
          font-size: 18px;
          font-family: '微软雅黑', serif;
          font-weight: 500;
          color: #666666;
          line-height: 32px
        }

      }


      .hove {
        position: absolute;
        width: 203px;
        height: 185px;
        box-shadow: 0px 9px 21px 3px rgba(76, 95, 126, 0.2);
        border-radius: 10px;
        top: 0;
        background: #1777EB;
        z-index: 99;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;

        .t {
          font-family: "微软雅黑 Light";
          font-weight: 600;
          color: #FFFFFF;
          font-size: 18px;
        }

        .cont {
          width: 100%;
          color: #FFFFFF;
          font-size: 13px;
          line-height: 20px;

        }

        .b {
          font-size: 15px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-family: "微软雅黑 Light";
          font-weight: 600;
          color: #FFFFFF;

          .icon {
            font-size: 20px;
          }
        }

      }

    }


    > a:hover .hove {
      color: #FFFFFF;
      background: #1777EB;
      transition: 1.2s;
      opacity: 1;

    }

    //
    //> a:nth-child(2n) > div {
    //  border-left: none;
    //  border-bottom: none;
    //}
    //

    //
    //> a:nth-child(7) > div, > a:nth-child(8) > div, > a:nth-child(9) > div {
    //}

    > a:nth-child(3n) > div {
      border-right: 1px solid rgba(212, 212, 222, 1);;
    }

    > a:nth-last-child(1), > a:nth-last-child(2), > a:nth-last-child(3) {
      border-bottom: 1px solid rgba(212, 212, 222, 1);;
    }

  }


  .solution {
    width: 1200px;
    margin: 20px auto;
    padding: 20px 0;
    overflow: hidden;

    .swiper-container {
      overflow: visible;
      cursor: grab;

      .swiper-wrapper {

        .swiper-slide {
          width: 300px;
          box-shadow: 0 0 20px #c5c0c0;
          height: 350px;
          background: #fff;
          transition: 300ms;
          transform: scale(0.8);
          flex-shrink: 0;
          border-radius: 7px;


          .box {
            width: 100%;
            height: 100%;

            .big {

              display: none;
              justify-content: space-evenly;
              align-items: center;
              flex-direction: column;
              height: 100%;

              .t {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #6A6B6B;
              }

              img {
                width: 213px;
                height: 150px;

              }

              .btn {
                background: #1777ec;
                border-radius: 6px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                padding: 6px 20px;
                cursor: pointer;
              }

            }

            .small {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-top: 45px;

              img {
                width: 190px;
                height: 190px;
              }

              .t {
                font-size: 25px;
                font-family: Microsoft YaHei;
                font-weight: 300;
                color: #6A6B6B;
                margin-top: 18px;
              }
            }

          }

        }


        .swiper-slide-active {
          transform: scale(1);

          .box {

            .big {
              display: flex;

              img {
                height: 190px;
              }
            }

            .small {
              visibility: hidden;
            }
          }
        }
      }
    }
  }


  .caseShow {
    width: 100%;
    margin-top: 100px;
    text-align: center;
    background-image: url("../../assets/caseShow/bg.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 65px;


    .list {
      margin: 20px auto 0;
      width: 1200px;
      font-size: 23px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > a > div {
        color: #FFFFFF;
        margin: 0 56px 89px;
        width: 185px;
        height: 66px;
        line-height: 66px;
      }

      > a div:hover {
        transition: 1s;
        background-color: #f04444;
      }

      > a > div:first-child {
        margin-left: 0;
      }

      > a:nth-child(4n+1) div {
        //border: 1px solid red;
        //margin-right: 0;
      }
    }

  }

  .CustomerLogoWall {
    width: 100%;
    background: #FFFFFF;

    .logoCarousel {
      margin: 0 auto;
      width: 1000px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .newsCenter {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    background: #EEEEEE;
    padding-bottom: 100px;

    .newsBox {
      width: 1200px;
      margin: 0 auto;

      .n {
        margin: 0 auto;
        width: 400px;
        border: 1px solid #1777ec;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        text-align: center;

        > div {
          width: 50%;
          height: 40px;
          line-height: 40px;
          font-family: Microsoft YaHei;
          font-size: 17px;
          font-weight: 300;
          color: #24439C;
          background-color: #FFFFFF;

        }

        .left {
          border-radius: 6px;
        }

        .right {
          border-radius: 6px;
        }

        .selected {
          background-color: #1777ec;
          color: #FFFFFF;
        }
      }


      .box {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {

          width: 31.5%;
          overflow: hidden;

          .ig {
            width: 100%;
            height: 260px;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: 100%;
            transform: scale(1);
          }

          img:hover {
            transform: scale(1.3);
            transition: all .3s;

          }

          .text {
            font-family: Microsoft YaHei;
            margin-top: 20px;

            .t {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
            }

            .time {
              font-size: 13px;
              font-weight: 400;
              color: #999999;
              margin: 22px 0 6px;
            }

            .txt {
              font-size: 15px;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

</style>
